import React, { useEffect,  useRef } from "react";
import Layout from "./layout.js";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@flaticon/flaticon-uicons/css/all/all.css";

import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import $ from "jquery";
import SliderImg1 from "../images/baagban-residency/slider-img.png";
import AboutImg from "../images/baagban-residency/above-view.png";
import MuskGallery2 from "../images/baagban-residency/img8.png";
import MuskGallery3 from "../images/baagban-residency/img9.png";
import MuskGallery4 from "../images/baagban-residency/img10.png";
import MuskGallery5 from "../images/baagban-residency/img7.jpg";
import SliderLogo from "../images/logo2.png";





window.jQuery = $;
window.$ = $;
global.jQuery = $;

window.addEventListener('scroll', function () {
  if (window.scrollY > 90) {
    document.getElementById('navbar_top')?.classList.add('fixed-top');
  } else {
    document.getElementById('navbar_top')?.classList.remove('fixed-top');
  }
});

export default function BaagbanResidency() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function Fancybox(props) {
    const containerRef = useRef(null);

    useEffect(() => {
      const container = containerRef.current;

      const delegate = props.delegate || '[data-fancybox]';
      const options = props.options || {};

      NativeFancybox.bind(container, delegate, options);

      return () => {
        NativeFancybox.unbind(container);
        NativeFancybox.close();
      };
    });

    return <div ref={containerRef}>{props.children}</div>;
  }

  const { t } = useTranslation();

  var DomesticTours = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    infinite: false,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnFocus: false,
    pauseOnHover: false,
  };
  var muskGallerySlider = {
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    fade: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };


  return (
    <Layout pageName={t("baagban-residency")}>
      <div className="baagban-residency-slider-main position-relative property_slider_mdiv ">
        <Slider {...DomesticTours} className="property_slider common_slider_arrow">
          <div className="slide-container">
            <div className="img-fill">
              <img src={SliderImg1} alt="Slider Img" />

            </div>
          </div>
        </Slider>
      </div>

      <section className="sticky_heading text-center pt-0 baagban-sticky-heading">
        <div className="container-fluid">
          <div className="w-100">
            <div className="sticky-nav-tabs">
              <div className="details-slider-info">
                <h1 className="details-slider-title">Baagban Residency</h1>
                <div className="slider-text-mdiv">
                  <p className="slider-text">2 & 3 BHK Apartments -  community living</p> <span>/</span>
                  <p className="slider-text slider-text-2">completed</p>
                </div>
              </div>
              <ul className="sticky-nav-tabs-container d-flex ">
                <li className="list-inline-item nav-item active"><a className="sticky-nav-tab font-roman text-uppercase" href="#about">About</a></li>
                <li className="list-inline-item nav-item"><a className="sticky-nav-tab font-roman text-uppercase" href="#residency_gallery">Gallery</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </section>

      <div className="wrapper">
        <section className="property-main-content" id="about">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <img src={AboutImg} className="img-fluid" alt="About Us" />

              </div>
              <div className="col-lg-7 mt-4 mt-lg-0">
                <h3 className="text-uppercase common-title"><span className="first-letter">A</span>bout</h3>
                <p className="grey-text common-des">With Unique style of 2 & 3 BHK apartments,
                  Baagban Group creates an ideal community living
                  experience in Rakhiyal. It also a very strategic
                  location for your home.
                </p>
                <p className="grey-text common-des">We truly believe that a personality like you deserve
                  a home that is benchmark in its on way, incomparable
                  and loaded with amenities. A new generation world

                </p>
                <p className="grey-text common-des">
                  class architectural style encompassing premium
                  life style Facet in all four towers with
                  2 & 3 BHK Residential Home.
                </p>
                <p className="grey-text common-des">
                  The Best Thing In Life Come In Small Packages...</p>
              </div>
            </div>
          </div>
        </section>
        <section className="property-amenities-content property-features-content pb-0 overflow-hidden" id="residency_gallery">
          <div className="container-fluid px-0">
            <h3 className="text-uppercase common-title common-title-mb text-center">RESIDENCY <span className="first-letter">G</span>allery</h3>
            <div className="row align-items-center">
              <Fancybox options={{ Carousel: { infinite: false, }, }}>
                <div className="col-12">
                  <Slider {...muskGallerySlider} className="musk-gallery common_slider_arrow baagbaan-elite-gallery">

                    <a className="musk-gallery-container" href={MuskGallery4} data-fancybox="musk-gallery">
                      <div className="img-fill musk-gallery-div">
                        <img src={MuskGallery4} alt="Slider Img" />
                      </div>
                    </a>
                    <a className="musk-gallery-container" href={MuskGallery2} data-fancybox="musk-gallery">
                      <div className="img-fill musk-gallery-div">
                        <img src={MuskGallery2} alt="Slider Img" />
                      </div>
                    </a>
                    <a className="musk-gallery-container" href={MuskGallery3} data-fancybox="musk-gallery">
                      <div className="img-fill musk-gallery-div">
                        <img src={MuskGallery3} alt="Slider Img" />
                      </div>
                    </a>
                    <a className="musk-gallery-container" href={MuskGallery4} data-fancybox="musk-gallery">
                      <div className="img-fill musk-gallery-div">
                        <img src={MuskGallery4} alt="Slider Img" />
                      </div>
                    </a>
                    <a className="musk-gallery-container" href={MuskGallery5} data-fancybox="musk-gallery">
                      <div className="img-fill musk-gallery-div">
                        <img src={MuskGallery5} alt="Slider Img" />
                      </div>
                    </a>
                    <a className="musk-gallery-container" href={MuskGallery2} data-fancybox="musk-gallery">
                      <div className="img-fill musk-gallery-div">
                        <img src={MuskGallery2} alt="Slider Img" />
                      </div>
                    </a>
                  </Slider>
                </div>
              </Fancybox>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}