import React, { useEffect } from "react";
import Header from './layout/header';
import Footer from './layout/footer';
import { seo } from '../seo';
import $ from 'jquery'
import { useTranslation } from "react-i18next";
window.jQuery = $;
window.$ = $;
global.jQuery = $;


export default function Layout({ children , pageName }) {
  const { t } = useTranslation(["translation", "errorMessage"]);

  useEffect(() => {
    seo({
      title: pageName + ' | ' + 'Baagban Group' ,
    });
  } , [t]);
  
  return (
    <>
      <Header />
      <main>{children}</main> 
      <Footer />
    </>
  )
}