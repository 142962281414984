import React, { useEffect, useState, useRef } from "react";
import Layout from "./layout.js";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@flaticon/flaticon-uicons/css/all/all.css";
import { useForm } from "react-hook-form";
import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import $ from "jquery";
import SliderImg1 from "../images/img1.png";
import SliderImg2 from "../images/musk-slider-img.png";
import Amenities1 from "../images/drop-off-zone.svg";
import MuskGallery1 from "../images/musk-gallery.png";
import MuskGallery2 from "../images/breadcrumb.png";
import MuskGallery3 from "../images/img14.jpg";
import MuskGallery4 from "../images/img15.jpg";
import MuskGallery5 from "../images/img16.jpg";
import MuskGallery6 from "../images/interior1.png";
import MuskGallery7 from "../images/interior2.png";
import MuskGallery8 from "../images/interior3.png";
import MuskGallery9 from "../images/view.png";
import MuskGallery10 from "../images/view1.png";
import SliderLogo from "../images/logo2.png";
import Amenities2 from "../images/children-play-area.svg";
import Amenities3 from "../images/senior-citizen-sitting.svg";
import Amenities4 from "../images/alloted-parking.svg";
import Amenities5 from "../images/gym.svg";
import Amenities6 from "../images/spacious-common-area.svg";
import Amenities7 from "../images/multipurpose-hall-prayer-room.svg";
import Amenities8 from "../images/theatre.svg";
import Amenities9 from "../images/elevators.svg";
import Amenities10 from "../images/rooftop-barbeque.svg";
import Amenities11 from "../images/solar-street-light.svg";
import Amenities12 from "../images/water-softening.svg";
import Amenities13 from "../images/library.svg";
import Amenities14 from "../images/european-elevation.svg";
import Amenities15 from "../images/cctv-surveillance.svg";
import Amenities16 from "../images/ev-charging-stations.svg";
import Amenities17 from "../images/power-back-up.svg";
import Amenities18 from "../images/video-intercom.svg";
import Amenities19 from "../images/indoor-games.svg";
import Amenities20 from "../images/rcc-road.svg";
import Amenities21 from "../images/kurbani.svg";
import Amenities22 from "../images/gusalkhana-for-mayyat.svg";
import Amenities23 from "../images/car-wash.svg";
import Features1 from "../images/metro-station.svg";
import Features2 from "../images/airport.svg";
import Features3 from "../images/kalupur-railyway-station.svg";
import Features4 from "../images/ashram-road.svg";
import Features5 from "../images/sidi-saiyed-mosque.svg";
import Features6 from "../images/school.svg";
import Features7 from "../images/hotels.svg";
import aboutBaagbanPDF from "../images/baagban-musk.pdf";
import aboutVideoBG from "../images/breadcrumb.png";
import baseMentImg from "../images/img11.png";
import groundFloorImg from "../images/ground-floor-plans.png";
import floorPlanImg from "../images/floor-plans.png";
import clusterPlanImg from "../images/cluset-plans.png";
import blockUnitImg from "../images/block-unit.png";
import { apiCall , alertifyMessage , manageNetworkError, onlyNumberWithSpaceAndPlusSign } from "../js/common.js";





window.jQuery = $;
window.$ = $;
global.jQuery = $;

window.addEventListener('scroll', function() {
  if (window.scrollY > 90) {
      document.getElementById('navbar_top')?.classList.add('fixed-top');
  } else {
      document.getElementById('navbar_top')?.classList.remove('fixed-top');
  } 



  var windscroll = $(window).scrollTop();
  if ($(window).scrollTop() >= $(".property_slider_mdiv").outerHeight()) {

      $('.property-main-content').addClass('fixed-heading');
      $('.sticky_heading').addClass('fixed-heading');
      $('.wrapper section').each(function(i) {
          if ($(this).position().top <= windscroll + 160) {
              $('.sticky-nav-tabs-container li.active').removeClass('active');
              $('.sticky-nav-tabs-container li').eq(i).addClass('active');
          }
      });
  } else {
      $('.property-main-content').removeClass('fixed-heading');
      $('.sticky_heading').removeClass('fixed-heading');
      $('.sticky-nav-tabs-container li.active').removeClass('active');
      $('.sticky-nav-tabs-container li:first').addClass('active');
  }
});


export default function BaagbanMusk() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation(["translation", "errorMessage"]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all"
  });
  
  const onSubmit = async(data) => {
    data.record_type = "enquiry";

    var submitContact = await apiCall( 'post' , 'send-mail.php' , data);

    if( submitContact.status !== false ){
      if( submitContact.data.status_code === 1 ){
        alertifyMessage("success" ,  submitContact.data.message ); 
        reset();
      } else {
        alertifyMessage("error" ,  submitContact.data.message ); 
      }
    } else {
      manageNetworkError(submitContact , t);
    }
  };


  function Fancybox(props) {
    const containerRef = useRef(null);
  
    useEffect(() => {
      const container = containerRef.current;
  
      const delegate = props.delegate || '[data-fancybox]';
      const options = props.options || {};
  
      NativeFancybox.bind(container, delegate, options);
  
      return () => {
        NativeFancybox.unbind(container);
        NativeFancybox.close();
      };
    });
  
    return <div ref={containerRef}>{props.children}</div>;
  }


  var DomesticTours = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnFocus: false,
    pauseOnHover: false,
  };
  var muskGallerySlider = {
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    fade: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  
  return (
    <Layout pageName={t("baagban-musk")}>
        <div className="position-relative property_slider_mdiv musk-main-slider">
         
          <Slider {...DomesticTours} className="property_slider common_slider_arrow animation-section">
            <div className="slide-container musk-slider-container">
              <div className="img-fill">
                <img src={SliderImg2}  alt="Slider Img"/>
              </div>
            </div>
            <div className="slide-container musk-slider-container">
              <div className="img-fill">
                <img src={MuskGallery1}  alt="Slider Img" className="musk-slider-2"/>
              </div>
            </div>
            <div className="slide-container musk-slider-container">
              <div className="img-fill">
                <img src={MuskGallery2}  alt="Slider Img"/>
              </div>
            </div>
          </Slider>   
        </div>

        <section className="sticky_heading text-center pt-0">
        <div className="container-fluid">
            <div className="w-100">
            <div className="sticky-nav-tabs">
              <div className="details-slider-info">
                <h1 className="details-slider-title">Baagban Musk</h1>
                <div className="slider-text-mdiv">
                  <p className="slider-text">3 BHK Apartments - Luxury Living</p> <span>/</span>
                  <p className="slider-text slider-text-2">Ongoing </p>
                </div>
              </div>
              <ul className="sticky-nav-tabs-container d-flex">
                <li className="list-inline-item nav-item active"><a className="sticky-nav-tab font-roman text-uppercase" href="#about">About</a></li>
                <li className="list-inline-item nav-item"><a className="sticky-nav-tab font-roman text-uppercase" href="#amenities">Amenities</a>
                </li>
                <li className="list-inline-item nav-item"><a className="sticky-nav-tab font-roman text-uppercase" href="#features">Features</a>
                </li>
                <li className="list-inline-item nav-item"><a className="sticky-nav-tab font-roman text-uppercase" href="#floor_plans">Floor Plans</a>
                </li>
                <li className="list-inline-item nav-item"><a className="sticky-nav-tab font-roman text-uppercase" href="#musk_gallery">Gallery</a>
                </li>
                <li className="list-inline-item nav-item"><a className="sticky-nav-tab font-roman text-uppercase" href="#location">Location</a>
                </li>
              </ul>
            </div>
          </div>
          </div> 
        
        </section>

        <div className="wrapper">
          <section className="property-main-content musk-property-main-section animation-section" id="about">  
            <div className="container">   
              <div className="row align-items-center">
                <div className="col-lg-5 fadeleft">
                  <img src={MuskGallery1}  className="img-fluid" alt="About Us"/>

                </div>
                <div className="col-lg-7 mt-4 mt-lg-0 faderight">
                  <h3 className="text-uppercase common-title"><span className="first-letter">A</span>bout</h3>
                  <p className="grey-text common-des">Welcome to Baagban Musk, an oasis of luxury living. Nestled amidst nature, this project offers spacious 3 BHK apartments with elegant interiors and a range of amenities. Enjoy a harmonious blend of comfort and tranquility, all within a prime location.</p>
                  <p className="grey-text common-des">Baagban Musk offers serene living with lush gardens, elegant interiors, and spacious 3 BHK apartments. Experience peace and luxury, all in one place.</p>
                  <p className="grey-text common-des">Baagban Musk offers a diverse array of serene retreats. Ascend to the Terrace Gazebo for elevated serenity. Explore lush gardens, nature's canvas, where tranquility blossoms. Gather, relax, and rejuvenate in the heart of Baagban Musk, a common garden oasis.</p>
                  <p className="grey-text common-des">Baagban Musk beckons with a promise of dynamic living. Move your body in the well-equipped gym, play your favorite games in the indoor arena, and thrive in a community designed for an active, vibrant lifestyle.</p>

              
                  <a href={ aboutBaagbanPDF} target="_blank" rel="noreferrer"  className="download-btn"><i className="fi fi-rr-file-pdf"></i>Download Brochure</a>
                </div>
              </div>
            </div>
          </section>

          <section className="property-amenities-content common-bg-gray animation-section" id="amenities">
            <div className="container">
              <h3 className="text-uppercase common-title common-title-mb text-center fadedown"><span className="first-letter">A</span>menities</h3>     
                <div className="row align-items-center  row-cols-2 row-cols-lg-3 row-cols-xl-5">
                  <div className="col mb-4 amenities_main fadedown">
                    <div className="amenities_box">
                      <img src={Amenities1}  className="amenities-img" alt="Amenities"/>
                      <p className="amenities_text font-roman text-uppercase">Drop Off Zone</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown">
                    <div className="amenities_box">
                      <img src={Amenities2}  className="amenities-img" alt="Amenities"/>
                      <p className="amenities_text font-roman text-uppercase">Children Play Area</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown">
                    <div className="amenities_box">
                      <img src={Amenities3}  className="amenities-img" alt="Senior Citizen Sitting"/>
                      <p className="amenities_text font-roman text-uppercase">Senior Citizen Sitting</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown">
                    <div className="amenities_box">
                      <img src={Amenities4}  className="amenities-img" alt="Alloted Parking"/>
                      <p className="amenities_text font-roman text-uppercase">Alloted Parking</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown">
                    <div className="amenities_box">
                      <img src={Amenities5}  className="amenities-img" alt="GYM"/>
                      <p className="amenities_text font-roman text-uppercase">GYM</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown delay1s">
                    <div className="amenities_box">
                      <img src={Amenities6}  className="amenities-img" alt="Spacious Common Area"/>
                      <p className="amenities_text font-roman text-uppercase">Spacious Common Area</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown delay1s">
                    <div className="amenities_box">
                      <img src={Amenities7}  className="amenities-img" alt="Prayer Room"/>
                      <p className="amenities_text font-roman text-uppercase">Prayer Room</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown delay1s">
                    <div className="amenities_box">
                      <img src={Amenities8}  className="amenities-img" alt="Theatre"/>
                      <p className="amenities_text font-roman text-uppercase">Theatre</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown delay1s">
                    <div className="amenities_box">
                      <img src={Amenities9}  className="amenities-img" alt="Elevators"/>
                      <p className="amenities_text font-roman text-uppercase">Elevators</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown delay1s">
                    <div className="amenities_box">
                      <img src={Amenities10}  className="amenities-img" alt="Rooftop Barbeque"/>
                      <p className="amenities_text font-roman text-uppercase">Rooftop Barbeque</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown delay2s">
                    <div className="amenities_box">
                      <img src={Amenities11}  className="amenities-img" alt="Solar Street Light"/>
                      <p className="amenities_text font-roman text-uppercase">Solar Street Light</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown delay2s">
                    <div className="amenities_box">
                      <img src={Amenities12}  className="amenities-img" alt="Water Softening"/>
                      <p className="amenities_text font-roman text-uppercase">Water Softening</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown delay2s">
                    <div className="amenities_box">
                      <img src={Amenities13}  className="amenities-img" alt="Library"/>
                      <p className="amenities_text font-roman text-uppercase">Library</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown delay2s">
                    <div className="amenities_box">
                      <img src={Amenities14}  className="amenities-img" alt="European Elevation"/>
                      <p className="amenities_text font-roman text-uppercase">European Elevation</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown delay2s">
                    <div className="amenities_box">
                      <img src={Amenities15}  className="amenities-img" alt="Amenities"/>
                      <p className="amenities_text font-roman text-uppercase">CCTV SURVEILLANCE</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown delay3s">
                    <div className="amenities_box">
                      <img src={Amenities16}  className="amenities-img" alt="EV Charging Stations"/>
                      <p className="amenities_text font-roman text-uppercase">EV Charging Stations</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown delay3s">
                    <div className="amenities_box">
                      <img src={Amenities17}  className="amenities-img" alt="Power Back-up"/>
                      <p className="amenities_text font-roman text-uppercase">Power Back-up</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown delay3s">
                    <div className="amenities_box">
                      <img src={Amenities18}  className="amenities-img" alt="Video Intercom"/>
                      <p className="amenities_text font-roman text-uppercase">Video Intercom</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown delay3s">
                    <div className="amenities_box">
                      <img src={Amenities19}  className="amenities-img" alt="Indoor Games"/>
                      <p className="amenities_text font-roman text-uppercase">Indoor Games</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown delay3s">
                    <div className="amenities_box">
                      <img src={Amenities20}  className="amenities-img" alt="RCC Road"/>
                      <p className="amenities_text font-roman text-uppercase">RCC Road</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown delay3s">
                    <div className="amenities_box">
                      <img src={Amenities21}  className="amenities-img" alt="Kurbani"/>
                      <p className="amenities_text font-roman text-uppercase">Kurbani</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown delay3s">
                    <div className="amenities_box">
                      <img src={Amenities22}  className="amenities-img" alt="Amenities"/>
                      <p className="amenities_text font-roman text-uppercase">Gusalkhana For Mayyat</p>
                    </div>
                  </div>
                  <div className="col mb-4 amenities_main fadedown delay3s">
                    <div className="amenities_box">
                      <img src={Amenities23}  className="amenities-img" alt="Amenities"/>
                      <p className="amenities_text font-roman text-uppercase">Car Wash</p>
                    </div>
                  </div>
                </div>
            </div>
          </section>

          <section className="property-amenities-content property-features-content animation-section" id="features">
            <div className="container">
              <h3 className="text-uppercase common-title text-center fadedown"><span className="first-letter">F</span>eatures</h3>     
              <div className="row align-items-center mt-5 fadeup">
                <div className="col-lg-3 col-md-6 col-6 mb-4 amenities_main">
                  <div className="amenities_box">
                    <img src={Features1}  className="amenities-img" alt="From Metro Station"/>
                    <p className="amenities_text font-roman text-uppercase">1 Minute</p>
                    <p className="font-14 mb-0 grey-text features-text2">From Metro Station</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6 mb-4 amenities_main">
                  <div className="amenities_box">
                    <img src={Features2}  className="amenities-img" alt="From Airport"/>
                    <p className="amenities_text font-roman text-uppercase">5 Minute</p>
                    <p className="font-14 mb-0 grey-text features-text2">From Airport</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6 mb-4 amenities_main">
                  <div className="amenities_box">
                    <img src={Features3}  className="amenities-img" alt="From Kalupur Railway Station"/>
                    <p className="amenities_text font-roman text-uppercase">10 Minute</p>
                    <p className="font-14 mb-0 grey-text features-text2">From Kalupur Railway Station</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6 mb-4 amenities_main">
                  <div className="amenities_box">
                    <img src={Features4}  className="amenities-img" alt="From Ashram Road"/>
                    <p className="amenities_text font-roman text-uppercase">5 Minute</p>
                    <p className="font-14 mb-0 grey-text features-text2">From Ashram Road</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6 mb-4 amenities_main">
                  <div className="amenities_box">
                    <img src={Features5}  className="amenities-img" alt="From Sidi Saiyed Mosque"/>
                    <p className="amenities_text font-roman text-uppercase">5 Minute</p>
                    <p className="font-14 mb-0 grey-text features-text2">From Sidi Saiyed Mosque</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6 mb-4 amenities_main">
                  <div className="amenities_box">
                    <img src={Features6}  className="amenities-img" alt="5 Schools"/>
                    <p className="amenities_text font-roman text-uppercase">5 Schools</p>
                    <p className="font-14 mb-0 grey-text features-text2">In The Vicinity</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6 mb-4 amenities_main">
                  <div className="amenities_box">
                    <img src={Features7}  className="amenities-img" alt="3 Hotels"/>
                    <p className="amenities_text font-roman text-uppercase">3 Hotels</p>
                    <p className="font-14 mb-0 grey-text features-text2">In The Vicinity</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="property-floor-content common-bg-gray animation-section" id="floor_plans">
            <div className="container">
              <h3 className="text-uppercase common-title common-title-mb text-center fadedown">Floor <span className="first-letter">P</span>lans</h3> 
              <ul className="d-flex justify-content-center border-bottom floor-plan-list" role="tablist">
                <li className="active text-uppercase font-roman tab-list" id="basement-tab" data-bs-toggle="tab" data-bs-target="#basement" type="button" role="tab" aria-controls="basement" aria-selected="true">Basement</li>
                <li className="text-uppercase font-roman tab-list" id="ground-floor-tab" data-bs-toggle="tab" data-bs-target="#ground-floor" type="button" role="tab" aria-controls="ground-floor" aria-selected="false">Ground Floor</li>
                <li className="text-uppercase font-roman tab-list" id="floor-plan-tab" data-bs-toggle="tab" data-bs-target="#floor-plan" type="button" role="tab" aria-controls="floor-plan" aria-selected="false">Floor Plan</li>
                <li className="text-uppercase font-roman tab-list" id="cluster-plan-tab" data-bs-toggle="tab" data-bs-target="#cluster-plan" type="button" role="tab" aria-controls="cluster-plan" aria-selected="false">Cluster Plan</li>
                <li className="text-uppercase font-roman tab-list" id="block-unit-tab" data-bs-toggle="tab" data-bs-target="#block-unit" type="button" role="tab" aria-controls="block-unit" aria-selected="false">Block Unit</li>
              </ul>
              <div className="tab-content p-3 pb-0 fadeup">
                <div className="tab-pane fade active show" id="basement" role="tabpanel" aria-labelledby="basement-tab">
                  <div className="row align-items-center">
                    <div className="col-12 text-center">
                      <img src={baseMentImg}  className="img-fluid floor-plans-img" alt="Basement"/>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="ground-floor" role="tabpanel" aria-labelledby="ground-floor-tab">
                  <div className="row align-items-center">
                    <div className="col-12 text-center">
                      <img src={groundFloorImg}  className="img-fluid floor-plans-img" alt="Ground Floor"/>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="floor-plan" role="tabpanel" aria-labelledby="floor-plan-tab">
                  <div className="row align-items-center">
                    <div className="col-12 text-center">
                      <img src={floorPlanImg}  className="img-fluid floor-plans-img" alt="Floor Plan"/>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="cluster-plan" role="tabpanel" aria-labelledby="cluster-plan-tab">
                  <div className="row align-items-center">
                    <div className="col-12 text-center">
                      <img src={clusterPlanImg}  className="img-fluid floor-plans-img" alt="Cluster Plan"/>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="block-unit" role="tabpanel" aria-labelledby="block-unit-tab">
                  <div className="row align-items-center">
                    <div className="col-12 text-center">
                      <img src={blockUnitImg}  className="img-fluid floor-plans-img" alt="Block Unit"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="property-amenities-content property-features-content pb-0 overflow-hidden animation-section" id="musk_gallery">
            <div className="container-fluid px-0">
              <h3 className="text-uppercase common-title common-title-mb text-center fadedown">Musk <span className="first-letter">G</span>allery</h3>     
              <div className="row align-items-center faderight">
              <Fancybox options={{Carousel: {infinite: false,},}}>
                <div className="col-12">
                  <Slider {...muskGallerySlider} className="musk-gallery common_slider_arrow baagbaan-musk-gallery">
                  
                      <a className="musk-gallery-container"  href= {MuskGallery1} data-fancybox="musk-gallery">
                        <div className="img-fill musk-gallery-div">
                          <img src={MuskGallery1} alt="Slider Img"/>
                        </div>
                      </a> 
                      <a className="musk-gallery-container"  href= {MuskGallery2} data-fancybox="musk-gallery">
                        <div className="img-fill musk-gallery-div">
                          <img src={MuskGallery2} alt="Slider Img"/>
                        </div>
                      </a> 
                      <a className="musk-gallery-container" href={MuskGallery3} data-fancybox="musk-gallery">
                        <div className="img-fill musk-gallery-div">
                          <img src={MuskGallery3} alt="Slider Img"/>
                        </div>
                      </a> 
                      <a className="musk-gallery-container" href={MuskGallery4} data-fancybox="musk-gallery">
                        <div className="img-fill musk-gallery-div">
                          <img src={MuskGallery4} alt="Slider Img"/>
                        </div>
                      </a> 
                      <a className="musk-gallery-container" href={MuskGallery5} data-fancybox="musk-gallery">
                        <div className="img-fill musk-gallery-div">
                          <img src={MuskGallery5} alt="Slider Img"/>
                        </div>
                      </a> 
                      <a className="musk-gallery-container" href={MuskGallery6} data-fancybox="musk-gallery">
                        <div className="img-fill musk-gallery-div">
                          <img src={MuskGallery6} alt="Slider Img"/>
                        </div>
                      </a> 
                      <a className="musk-gallery-container" href={MuskGallery7} data-fancybox="musk-gallery">
                        <div className="img-fill musk-gallery-div">
                          <img src={MuskGallery7} alt="Slider Img"/>
                        </div>
                      </a> 
                      <a className="musk-gallery-container" href={MuskGallery8} data-fancybox="musk-gallery">
                        <div className="img-fill musk-gallery-div">
                          <img src={MuskGallery8} alt="Slider Img"/>
                        </div>
                      </a> 
                      <a className="musk-gallery-container" href={MuskGallery9} data-fancybox="musk-gallery">
                        <div className="img-fill musk-gallery-div">
                          <img src={MuskGallery9} alt="Slider Img"/>
                        </div>
                      </a> 
                      <a className="musk-gallery-container" href={MuskGallery10} data-fancybox="musk-gallery">
                        <div className="img-fill musk-gallery-div">
                          <img src={MuskGallery10} alt="Slider Img"/>
                        </div>
                      </a> 
                  </Slider> 
                </div>  
              </Fancybox>
              <div className="video-section no-padding">
                <div className="d-flex">
                  <div className="position-relative col-md-12 video">
                    <Fancybox options={{ Carousel: { infinite: false, }, }}>
                      <button type="button" className="btn btn-play fancy" data-src="https://youtu.be/c6T4_2ALmDc?si=CAdP_q2kaUPYPf1n" data-fancybox="wedding-function-photos"><span className="play-icon fi fi-rs-play" aria-label="Play"></span></button>
                    </Fancybox>
                    <img src={aboutVideoBG} className="img-responsive w-100 bg-thumb-img" alt="Video" />
                  </div>
                </div>
              </div>
              </div>
            </div>
          </section>

          <section className="property-location-content no-padding animation-section" id="location">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-6 form-section fadeleft">
                  <h3 className="text-uppercase common-title"><span className="first-letter">L</span>ocation</h3> 
                  <ul className="list-unstyled location-address mt-0">
                    <li className="contact-detail">FP 120, Next to Rifle Club, Khanpur, Ahmedabad City, Ahmedabad, Gujarat-380001</li>
                    <li><a href="mailto:baagbanmusk@gmail.com" className="sofia-font font-dark">baagbanmusk@gmail.com</a></li>
                    <li><a href="tel:+916356603030" className="sofia-font font-dark">+91 63566 03030</a></li>
                  </ul>
                  <div className="contact-form">
                    <form id="property-form" onSubmit={handleSubmit(onSubmit)}>
                    <h3 className="text-uppercase common-title contact-fotm-title mb-3"><span className="first-letter">S</span>end Enquiry</h3> 
                      <div className="row">
                        <div className="col-md-6 form-field">
                          <label className="form-label">{t('name')}</label><span className="text-danger">*</span>
                          <input type="text" className="form-control" name="name" placeholder={t('name')}
                            {...register("name", {
                              required: true,
                              setValueAs: (value) => value.trim(),
                            })}/>
                          {errors.name && (<span className="text-danger">{t('errorMessage:required-enter-name')}</span>)}
                        </div>
                        <div className="col-md-6 form-field">
                          <label className="form-label">{t('mobile-no')}</label><span className="text-danger">*</span>
                          <input type="text" className="form-control" placeholder={t('mobile-no')} maxLength="15" name="mobile_number"
                            {...register("mobile_number", {
                              required: t('errorMessage:required-enter-mobile-no'),
                              minLength: {
                                value: 8,
                                message: t('errorMessage:enter-atleast-character', {character: 8}),
                              },
                              setValueAs: (value) => value.trim(),
                            })}
                            onChange={onlyNumberWithSpaceAndPlusSign} />
                          {errors.mobile_number && (<span className="text-danger">{errors.mobile_number?.message}</span>)}
                        </div>
                        <div className="col-md-12 form-field">
                          <label className="form-label">{t('email')}</label>
                          <input type="text" className="form-control" name="email" placeholder={t('email')}
                          {...register("email", {
                            required: false,
                            setValueAs: (value) => value.trim(),
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: t('errorMessage:required-enter-valid-email'),
                            },
                          })}/>
                          {errors.email && (<span className="text-danger">{errors.email?.message}</span>)}
                        </div>
                        <div className="col-lg-12 d-none">
                          <div className="form-group">
                            <label htmlFor="webname">{t('webname')}</label>
                            <input type="text" name="webname" className="form-control" id="webname" placeholder={t('webname')}
                              {...register("webname", { required: false })}/>
                          </div>
                        </div>
                        <div className="col-12 form-field">
                          <label className="form-label">Message</label>
                          <textarea className="form-control" name="message" rows="4" placeholder="Message"
                          {...register("message", {
                            required: false,
                            setValueAs: (value) => value.trim()
                          })}></textarea>
                        </div>
                        <div className="col-12 text-right">
                          <button type="submit" className="btn btn-lg theme-bg mt-2 submit-button text-white">{t("send")}</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-6 h-100 faderight">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7343.308647591757!2d72.57697734914595!3d23.03646110557966!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e845c63227455%3A0x2fcd2c7c1e0f56b!2sThe%20Ahmedabad%20Military%20And%20Rifle%20Training%20Association!5e0!3m2!1sen!2sin!4v1708433218612!5m2!1sen!2sin" width="100%" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className="map-iframe" title="map"></iframe>
                </div>
              </div>
            </div>
          </section>
        </div>
    </Layout>
  );
}