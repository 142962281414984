import React from "react";
import { Route, Routes } from "react-router-dom";
import * as Constants from "../config/constants";
import Home from "../pages/home";
import Contact from "../pages/contact-us.js";
import About from "../pages/about-us.js";
import Error404 from "../pages/404-error";
import BaagbanMusk from "../pages/baagban-musk.js";
import BaagbanElite from "../pages/baagban-elite.js";
import BaagbanResidency from "../pages/baagban-residency.js";
import Projects from "../pages/projects.js";
import OngoingProjects from "../pages/ongoing-projects.js";
import CompletedProjects from "../pages/completed-projects.js";


export const FrontendRoute = () => {
    return (
        <Routes>
            <Route key="frontend-1" path={Constants.LANDING_PAGE_URL} element={<Home />} />
			<Route key="frontend-1" path={Constants.INDEX_PAGE_URL} element={<Home />} />
            <Route key="frontend-2" path={Constants.HOME_PAGE_URL} element={<Home />} />
            <Route key="frontend-3" path={Constants.CONTACT_US_PAGE_URL} element={<Contact />} />
            <Route key="frontend-4" path={Constants.ABOUT_US_PAGE_URL} element={<About />} />
            <Route key="frontend-5" path="*" element={<Error404 />} />
            <Route key="frontend-6" path={Constants.BAAGBAN_MUSK_PAGE_URL} element={<BaagbanMusk />} />
            <Route key="frontend-7" path={Constants.BAAGBAN_ELITE_PAGE_URL} element={<BaagbanElite/>} />
            <Route key="frontend-8" path={Constants.BAAGBAN_RESIDENCY_PAGE_URL} element={<BaagbanResidency/>} />    
            <Route key="frontend-9" path={Constants.PROJECTS_PAGE_URL} element={<Projects/>} />    
            <Route key="frontend-10" path={Constants.ONGOING_PROJECTS_PAGE_URL} element={<OngoingProjects/>} />    
            <Route key="frontend-11" path={Constants.COMPLETED_PROJECTS_PAGE_URL} element={<CompletedProjects/>} />    
			<Route key="frontend-12" path={Constants.INDEX_PAGE_URL} element={<Home />} />
        </Routes>
    )
}