import React, {useEffect, useState} from "react";
import { NavLink, Link  } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import * as Constants from "../../config/constants";
import $ from "jquery";
import {toggleNav} from '../../js/common';
import { useTranslation } from "react-i18next";
import mainLogo from '../../images/logo.svg';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

export default function Header() {
  
$(document).on('click' , 'a.sticky-nav-tab[href*="#"]' , function(event){
  var hash = this.hash;
  if (hash !== "" && $(hash).length) {
    event.preventDefault();

    if (!$(this).attr("data-bs-toggle")) {
      $("html, body").animate(
        {
          scrollTop:
            $(hash).offset().top -
            $(".navbar").outerHeight() -
            50
        },
        0
      );
    }
  }
});

  $(window).scroll(function() {    
    var scroll = $(window).scrollTop();

    if (scroll >= 100) {
        $(".twt-navbar-common").addClass("fixed");
    } else {
        $(".twt-navbar-common").removeClass("fixed");
    }
  });








  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleDropdownClick = (event) => {
      setIsOpen(!isOpen);
      event.stopPropagation();
  };
 
  useEffect(() => {
    const navMain = document.querySelector("#navMain");
    const minHeight = navMain.offsetHeight;
    document.querySelector(".main-navbar-wrapper").style.minHeight = minHeight + "px";
    var $animation_elements = $('.animation-section');
    var $window = $(window);

    function check_if_in_view() {
      var window_height = $window.height();
      var window_top_position = $window.scrollTop() - 90;
      var window_bottom_position = (window_top_position + window_height);
      
      $.each($animation_elements, function() {
          var $element = $(this);
          var element_height = $element.outerHeight();
          var element_top_position = $element.offset().top;
          var element_bottom_position = (element_top_position + element_height);
      
          //check to see if this current container is within viewport
          if ((element_bottom_position >= window_top_position) &&
              (element_top_position <= window_bottom_position)) {
          $element.addClass('animation');
          } else {
          $element.removeClass('animation');
          }
      });
    }
    $window.on('scroll resize', check_if_in_view);
    $window.trigger('scroll');

  }, []);

  return (
 
  <header>
    
  <div className="main-navbar-wrapper">
    <nav className="navbar twt-navbar twt-navbar-common navbar-expand-lg bg-light nav-light px-0 py-2 py-lg-3" id="navMain">
      <div className="container-fluid  twt-navbar-container">
        <div className="logo-div">
          <NavLink className="navbar-brand p-3 p-lg-0" to={Constants.HOME_PAGE_URL}>
            <img src={mainLogo} alt={t('baagban-group')} className="brand-logo-img"/>
          </NavLink>
        </div>
        <button type="button" className="btn navbar-toggler border-0" id="slide-toggle" onClick={toggleNav}><span className="navbar-dash" /><span className="navbar-dash" /><span className="navbar-dash" /></button>
        { /* #note: remove 'flex-fill' class from below to align menu right */}
        <div className="slide navbar-slide flex-fill ms-lg-3" id="slideNav">
          <ul className="navbar-nav ms-auto p-0" id="elastic_parent" data-bs-targets=".nav-item">
            <li className="nav-item">
              <NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} to={Constants.HOME_PAGE_URL}>{t("home")}</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} to={Constants.ABOUT_US_PAGE_URL}>{t("about")}</NavLink>
            </li>
            <li className="nav-item dropdown">
              <NavLink to={Constants.PROJECTS_PAGE_URL} className={({ isActive }) => { const linkClasses = ["nav-link dropdown-toggle d-lg-flex d-none"]; if (isActive) { linkClasses.push("active"); } return linkClasses.join(" "); }}  ><span className="">{t("projects")}</span> <i className="fi fi-rr-angle-small-down"></i></NavLink>  

              <NavLink to={Constants.PROJECTS_PAGE_URL} className={({ isActive }) => { const linkClasses = ["nav-link dropdown-toggle d-flex d-lg-none"]; if (isActive) { linkClasses.push("active"); } return linkClasses.join(" "); }}  onClick={handleDropdownClick} data-bs-toggle="dropdown"><span className="">{t("projects")}</span> <i className="fi fi-rr-angle-small-down"></i></NavLink>  

              <ul className="dropdown-menu">
                
                <li><Link to={Constants.PROJECTS_PAGE_URL} className="dropdown-item d-flex d-lg-none">All</Link></li>
                <li><Link to={Constants.ONGOING_PROJECTS_PAGE_URL} className="dropdown-item">Ongoing Projects</Link></li>
                <li><Link to={Constants.COMPLETED_PROJECTS_PAGE_URL} className="dropdown-item">Completed Projects</Link></li>
              </ul>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} to={Constants.CONTACT_US_PAGE_URL}>{t("contact-us")}</NavLink>
            </li>
          </ul>
        </div>
        <div className="logo-div w-auto d-lg-flex d-none">
          <a href="tel:+916356603030" className="btn btn-lg btn-light">Call Us Now</a>
        </div>
      </div>
    </nav>
  </div>
  <div className="whatsapp-main-fixed-part shadow whatsapp-icon whatsapp-right">
      <div className="circle">
          <a className=" link-unstyled cover-a-tag" rel="noreferrer" target="_blank"
              href="https://api.whatsapp.com/send?phone=916356603030">
              <i className="fab fa-whatsapp fa-2x text-white whatsaap-color"></i>
          </a>
      </div>
  </div>
  {/* navbar end  */}
  </header>
  );
}