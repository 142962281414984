import { Link  } from "react-router-dom";
import * as Constants from "../config/constants.js";
import React, { useEffect, useState } from "react";
import Layout from "./layout.js";
import { useForm } from "react-hook-form";
import "@flaticon/flaticon-uicons/css/all/all.css";
import { useTranslation } from "react-i18next";
import { apiCall , alertifyMessage , manageNetworkError , onlyNumberWithSpaceAndPlusSign } from "../js/common.js";

export default function Contact() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const { t } = useTranslation(["translation", "errorMessage"]);

  useEffect(() => {
    // document.querySelector("input[type='tel']").setAttribute("maxlength", "15");
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "all"
  });
  
  const onSubmit = async (data) => {
    data.record_type = "contact us";
    var submitContact = await apiCall( 'post' , 'send-mail.php' , data);

    if( submitContact.status !== false ){
      if( submitContact.data.status_code === 1 ){
        alertifyMessage("success" ,  submitContact.data.message ); 
        reset();
      } else {
        alertifyMessage("error" ,  submitContact.data.message ); 
      }
    } else {
      manageNetworkError(submitContact , t);
    }
  };

  return (
    <Layout pageName={t("contact-us")}>
      <header className="breadcrumb about-us-bread">
        <div className="container">
          <div className="breadcrumb-sec">
            <h1 className="page-title">{t("contact-us")}</h1>
            <div className="breadcrumb-menu ml-auto">
              <ul>
                <li className="nav-item"><Link to={Constants.HOME_PAGE_URL} className="nav-link">{t("home")}</Link></li>
                <li className="nav-item">/</li>
                <li className="nav-item">{t("contact-us")}</li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      <main className="page-height">
        <section className="contact-us-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 pt-4">
                <div className="address-box">
                  <div className="address-icon">
                    <i className="fas fa-map-marker-alt" />
                  </div>
                  <h3 className="title">Office Address</h3>
                  <p className="description address-contact">
                  FP 120, Next to Rifle Club, Khanpur, Ahmedabad City, Ahmedabad, Gujarat-380001
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 pt-4">
                <div className="address-box">
                  <div className="address-icon">
                    <i className="fas fa-phone call-icon" />
                  </div>
                  <h3 className="title">Phone Number</h3>
                  <a href="tel:+916356603030" className="description">
                  +91 63566 03030
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 pt-4">
                <div className="address-box">
                  <div className="address-icon">
                    <i className="fa-solid fa-envelope-open"></i>
                  </div>
                  <h3 className="title">Email</h3>
                  <a href="mailto:baagbanmusk@gmail.com" className="description">
                  baagbanmusk@gmail.com
                  </a>
                </div>
              </div>
            </div>
            <div className="contact-form mt-5">
              <form onSubmit={handleSubmit(onSubmit)} id="contact-us-form">
                <div className="row">
                  <div className="form-group col-md-6 pb-2">
                  <label htmlFor="name">{t('name')}<span className="text-danger">*</span>
                          </label>
                    <input type="text"className="form-control"name="name"placeholder={t('name')}
                          {...register("name", {
                              required: t('errorMessage:required-enter-name'),
                              setValueAs: (value) => value.trim(),
                            })}/>
                     {errors.name && (<span className="text-danger">{errors.name?.message}</span>)}
                  </div>
                  
                  
                  <div className="form-group col-md-6 pb-2">
                    <label htmlFor="mobile_number">{t('mobile-no')}<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder={t('mobile-no')} maxLength="15" name="mobile_number"
                            {...register("mobile_number", {
                              required: t('errorMessage:required-enter-mobile-no'),
                              minLength: {
                                value: 8,
                                message: t('errorMessage:enter-atleast-character', {character: 8}),
                              },
                              setValueAs:value => value.trim()
                            })}
                            onChange={onlyNumberWithSpaceAndPlusSign}
                          />
                    {errors.mobile_number && (<span className="text-danger">{errors.mobile_number?.message}</span>)}
                  </div>
                  <div className="form-group col-md-12 pb-2">
                    <label htmlFor="email">
                              {t('email')}
                            </label>
                      <input type="text" className="form-control"name="email"placeholder={t('email')} 
                      {...register("email", {
                        required: false,
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: t('errorMessage:required-enter-valid-email'),
                        },
                      })}
                    />
                    {errors.email && (<span className="text-danger">{errors.email?.message}</span>)}
                  </div>
                  <div className="col-lg-12 d-none">
                      <div className="form-group">
                        <label htmlFor="webname">{t('webname')}</label>
                        <input type="text" name="webname" className="form-control" id="webname" placeholder={t('webname')}
                          {...register("webname", { required: false })}/>
                      </div>
                    </div>
                  
                  <div className="form-group col-md-12 pb-2">
                    <label htmlFor="text">{t('message')}</label>
                    <textarea className="form-textarea form-control" name="message" defaultValue={""} placeholder={t('message')}
                      {...register("message", { required: false })}/>
                  </div>
                  <div className="form-group col-md-12 ">
                    <button type="submit" className="btn btn-primary submit-btn">
                    {t("send")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>

      <section className="map py-0">
        <div className="map-wrap">
          <iframe  width="100%" className="iframe-contact" title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7343.308647591757!2d72.57697734914595!3d23.03646110557966!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e845c63227455%3A0x2fcd2c7c1e0f56b!2sThe%20Ahmedabad%20Military%20And%20Rifle%20Training%20Association!5e0!3m2!1sen!2sin!4v1708433218612!5m2!1sen!2sin" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade "></iframe>
        </div>
      </section>
    
    </Layout>
  );
}
