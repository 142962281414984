import * as twtConfig from "./twt_config";  

export const API_BASE_URL = twtConfig.API_BASE_URL;
export const BACKEND_FILE_URL = twtConfig.BACKEND_FILE_URL;
export const SITE_URL = twtConfig.SITE_URL;
export const BASE_PATH = twtConfig.BASE_PATH;
export const LOGIN_COOKIE_NAME = twtConfig.LOGIN_COOKIE_NAME;
export const LANGUAGE_FOLDER_URL = twtConfig.LANGUAGE_FOLDER_URL;

export const LANDING_PAGE_URL = "/";
export const INDEX_PAGE_URL = "/index.html";
export const HOME_PAGE_URL = "/baagban-group.html";
export const ABOUT_US_PAGE_URL = "/about.html";
export const CONTACT_US_PAGE_URL = "/contact-us.html";
export const BAAGBAN_MUSK_PAGE_URL = "/baagban-musk.html";
export const BAAGBAN_ELITE_PAGE_URL = "/baagban-elite.html";
export const BAAGBAN_RESIDENCY_PAGE_URL = "/baagban-residency.html";
export const PROJECTS_PAGE_URL = "/projects.html";
export const ONGOING_PROJECTS_PAGE_URL = "/ongoing-projects.html";
export const COMPLETED_PROJECTS_PAGE_URL = "/completed-projects.html";

