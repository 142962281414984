import React from "react";
import '@fortawesome/fontawesome-free/css/all.css';
import { Link } from "react-router-dom";
import * as Constants from "../../config/constants";
import { useTranslation } from "react-i18next";
import mainLogo from '../../images/logo.svg';


export default function Footer() {
	const { t } = useTranslation();
	return (
		<footer className="footer back-img dark-img-bg dark-bg">
           
            <div className="container">                        
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3 mb-sm-4 footer-div footer-div-1 order-2 order-lg-1">
                        <h5 className="footer-title text-uppercase font-weight-bold mb-3"><span className="first-letter">Q</span>uick Links</h5>
                        <ul className="list-unstyled">
                            <li><Link to={Constants.HOME_PAGE_URL} className="footer-link">Home</Link></li>
                            <li><Link to={Constants.ABOUT_US_PAGE_URL} className="footer-link">About</Link></li>
                            <li><Link to={Constants.PROJECTS_PAGE_URL} className="footer-link">Projects</Link></li>
                            <li><Link to={Constants.CONTACT_US_PAGE_URL} className="footer-link">Contact Us</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-4 mb-3 mb-sm-4 footer-div order-1 order-lg-2">
                        <div className="footer-div footer-div-2 text-center">
                            <Link className="navbar-brand" to={Constants.HOME_PAGE_URL}>
                               
                                <img src={mainLogo} alt={t('baagban-group')} className="brand-logo-img mb-3"/>
                            </Link>
                            <p className="footer-about">Baagban Group creates an ideal community living experience in Ahmedabad with luxury homes. All luxury homes are available with best in class amenities. </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-lg-5 mb-3 mb-sm-4 footer-div footer-div-3 order-3 order-lg-3">
                        <h5 className="footer-title text-uppercase font-weight-bold mb-3"><span className="first-letter">C</span>ontact Details </h5>
                        <ul className="list-unstyled">
                            <li>FP 120, Next to Rifle Club, Khanpur, Ahmedabad City, Ahmedabad, Gujarat-380001</li>
                            <li><a href="mailto:baagbanmusk@gmail.com" className="footer-link">baagbanmusk@gmail.com</a></li>
                            <li><a href="tel:+916356603030" className="footer-link">+91 63566 03030</a></li>
                        </ul>
                    </div>
                </div>
								<div className="copyright d-flex justify-content-between align-items-center py-4 border-top footer-border">
									<div className="mb-0"><p className="mb-0">Copyright &copy; - {(new Date().getFullYear())} Baagban Group.{t('all-rights-reserved')} </p>
										<p className="developed mb-0">{t('developed-by')} <a href="https://www.thewildtigers.com" target="_blank" rel="noreferrer" className="ms-1">TWT</a>.</p>
									</div>
								</div>
            </div>
		</footer>
	);
}

