import React, { useEffect,} from "react";
import * as Constants from "../config/constants.js";
import Layout from "./layout.js";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {  Link } from "react-router-dom";
import "@flaticon/flaticon-uicons/css/all/all.css";
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import BaagbanEliteImg from "../images/baagban-elite.jpg";
import BaagbanResidencyImg from "../images/baagban-residency.jpg";



export default function BaagbanMusk() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();


  return (
    <Layout pageName={t("completed-projects")}>
      <header className="breadcrumb about-us-bread">
        <div className="container">
          <div className="breadcrumb-sec">
            <h1 className="page-title">{t("completed-projects")}</h1>
            <div className="breadcrumb-menu ml-auto">
              <ul>
                <li className="nav-item"><Link to={Constants.HOME_PAGE_URL} className="nav-link">{t("home")}</Link></li>
                <li className="nav-item">/</li>
                <li className="nav-item">{t("completed-projects")}</li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <section className="property-floor-content common-bg-gray" id="floor_plans">
        <div className="container">
          <h3 className="text-uppercase common-title common-title-mb text-center"><span className="first-letter">P</span>rojects</h3>
            <div className="row align-items-center">
              <div className="col-sm-6 col-md-4 my-3 all-project-box">
              <Link to={Constants.BAAGBAN_ELITE_PAGE_URL} className="projects-link">
                <div className="all-project-img-div">
                  <img src={BaagbanEliteImg} className="w-100 text-center img-fluid mb-3 projects-img" alt="BAAGBAN MUSK" />
                  <div className="working-tag">
                    <p class="projects-des">Completed</p>
                  </div>
                </div>
                <p className="mb-0 font-25 font-weight-bold text-uppercase font-roman projects-name">BAAGBAN Elite</p>
                <p className="my-0 projects-des">3 BHK - Luxury Living </p>
              </Link>
              </div>
              <div className="col-sm-6 col-md-4 my-3 all-project-box">
              <Link to={Constants.BAAGBAN_RESIDENCY_PAGE_URL} className="projects-link">
                <div className="all-project-img-div">
                  <img src={BaagbanResidencyImg} className="w-100 text-center img-fluid mb-3 projects-img" alt="BAAGBAN MUSK" />
                  <div className="working-tag">
                    <p class="projects-des">Completed</p>
                  </div>
                </div>
                <p className="mb-0 font-25 font-weight-bold text-uppercase font-roman projects-name">BAAGBAN Residency</p>
                <p className="my-0 projects-des ">2 & 3 BHK - Community Living</p>
              </Link>
              </div>
            </div>
        </div>
      </section>
    </Layout>
  );
}