import React, { useEffect, useRef }  from "react";
import Layout from "./layout.js";
import { useTranslation } from "react-i18next";
import {Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import $ from "jquery";
import * as Constants from "../config/constants.js";



import BaagbanMusk from "../images/baagban-musk.jpg";
import BaagbanElite from "../images/baagban-elite.jpg";
import BaagbanResidency from "../images/baagban-residency.jpg";
import OurStatistics from "../images/our-statistics.jpg";
import Delivering1 from "../images/delivering-img1.jpg";
import Delivering2 from "../images/delivering-img2.jpg";
import SliderImg1 from "../images/img1.png";
import SliderImg2 from "../images/home-slider-main.png";
import aboutVideoBG from "../images/breadcrumb.png";
import SliderLogo from "../images/logo2.png";
window.jQuery = $;
window.$ = $;
global.jQuery = $;



export default function Home() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var SliderFor = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    infinite: false,
    speed: 1000,
    autoplay: true,
    pauseOnFocus: false,
    pauseOnHover: false,
  };
  

  function Fancybox(props) {
    const containerRef = useRef(null);
  
    useEffect(() => {
      const container = containerRef.current;
  
      const delegate = props.delegate || '[data-fancybox]';
      const options = props.options || {};
  
      NativeFancybox.bind(container, delegate, options);
  
      return () => {
        NativeFancybox.unbind(container);
        NativeFancybox.close();
      };
    });
  
    return <div ref={containerRef}>{props.children}</div>;
  }

  const {t} = useTranslation();

  return (
    <Layout pageName={t('home')}>


{/* <!-- banner slider section --> */}
  <div className="sld-wrp overflow-hidden animation-section">
    <div className="property_slider_mdiv musk-main-slider">
      <Slider {...SliderFor} className="slider-for common_slider_arrow">
        <div className="slide-container">
          
          <div className="img-fill">
            <img src={SliderImg2} className="fadeup"  alt="slider"/>
            <div className="info">
              <div className="container">
                {/* <h2 className="slider-sm-title text-uppercase">3 BHK</h2>
                <h4 className="slider-title text-uppercase">BAAGBAN <br/>Musk</h4> */}
                <div className="slider-logo-div">
                   <img src={SliderLogo} className="fadeup"  alt="slider"/> 
                </div>
                <div className="d-flex slider-btn-div">
                  <Link to={Constants.BAAGBAN_MUSK_PAGE_URL} className="btn btn-lg theme-bg me-0 me-sm-3 common-btn-1 slider-musk-btn">View Project</Link>
                  <a href="tel:+916356603030" className="btn btn-lg btn-light slider-musk-btn musk-slider-call-btn">Call Us Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  </div>

  {/* <!-- about section --> */}
  <section className="about-section back-img light-img-bg common-bg animation-section" >
    <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex flex-column align-items-center justify-content-center mt-5 mt-md-0 order-2 order-md-1 fadedown">
            <div className="masking-img">
              <h3 className="masking-text">20+</h3>
            </div>
            <p className="my-0 font-25 about-des">Years of Experience</p>
          </div>
          <div className="col-md-6 order-1 order-md-2 fadeup">
            <h1 className="text-uppercase section-title">About <span className="first-letter">B</span>aagban Group</h1>
            <p className="grey-text common-des">Baagban Group creates an ideal community living experience in Ahmedabad. It also a very strategic location for your home.  Since beginning, Baagban Group stands for expertise and quality. We create best in class luxury homes 2 BHK & 3 BHK to bring our customer's dreams into reality. Sanaulla R. Pathan is a man of great vision. A thinker. A doer. A go-getter. An Entrepreneur who is forever committed towards offering the best value and delivering high returns to his stakeholders. Over the years, Sanaulla R. Pathan has built a huge empire in real estate by implementing great ideas that drive progress for not only the end users, but also the society at large.</p>
            <Link to={Constants.ABOUT_US_PAGE_URL} className="btn btn-lg btn-bordered-dark me-3">Read More</Link>
          </div>
        </div>
    </div>
  </section>

  {/* <!-- all project section --> */}
  <section className="all-project-section common-bg common-bg-2 animation-section">
    <div className="container">
      <div className="main-title text-center m-auto faderight">
        <h2 className="text-uppercase section-title">Our <span className="first-letter">P</span>rojects</h2>
        <p className="grey-text pb-2 common-des text-center mb-4">A new generation world class architectural style encompassing premium life style Facet in all four towers with 2 & 3 BHK Residential Home.</p>
      </div>
      <div className="row fadeleft">
        <div className="col-sm-6 col-md-4 my-lg-0 my-3 all-project-box">
          <Link to={Constants.BAAGBAN_MUSK_PAGE_URL} className="projects-link">
            <div className="all-project-img-div">
              <img src={BaagbanMusk}  className="w-100 text-center img-fluid mb-3 projects-img" alt="BAAGBAN MUSK"/>
              <div className="working-tag">
                <p class="projects-des">Ongoing</p>
              </div>
            </div>
            <p className="mb-0 font-25 font-weight-bold text-uppercase font-roman projects-name">BAAGBAN MUSK</p>
            <p className="my-0 projects-des">3 BHK - Luxury Living </p>
          </Link>
        </div>
        <div className="col-sm-6 col-md-4 my-lg-0 my-3 all-project-box">
          <Link to={Constants.BAAGBAN_ELITE_PAGE_URL} className="projects-link">
            <div className="all-project-img-div">
              <img src={BaagbanElite}  className="w-100 text-center img-fluid mb-3 projects-img" alt="BAAGBAN MUSK"/>
              <div className="working-tag">
                <p class="projects-des">Completed</p>
              </div>
            </div>
            <p className="mb-0 font-25 font-weight-bold text-uppercase font-roman projects-name">BAAGBAN Elite</p>
            <p className="my-0 projects-des">3 BHK - Luxury Living </p>
          </Link>
        </div>
        <div className="col-sm-6 col-md-4 my-lg-0 my-3 all-project-box">
          <Link to={Constants.BAAGBAN_RESIDENCY_PAGE_URL} className="projects-link">
            <div className="all-project-img-div">
              <img src={BaagbanResidency}  className="w-100 text-center img-fluid mb-3 projects-img" alt="BAAGBAN MUSK"/>
              <div className="working-tag">
                <p class="projects-des">Completed</p>
              </div>
            </div>
            <p className="mb-0 font-25 font-weight-bold text-uppercase font-roman projects-name">BAAGBAN Residency</p>
            <p className="my-0 projects-des ">2 & 3 BHK - Community Living</p>
          </Link>
        </div>
      </div>
    </div>
  </section>

  {/* <!-- counter secton --> */}
  <section className="counter-section dark-bg animation-section">
    <div className="container">
      <div className="row position-relative">
        <div className="col-lg-7 col-md-12 order-2 order-lg-1 fade">
          <img src={OurStatistics}  className="img-fluid mb-3 d-none d-sm-block statistics-img" alt="Statistics"/>

        </div>
        <div className="offset-lg-5 col-lg-6 col-md-12 order-1 order-lg-2 statistics-block mb-lg-0 mb-4">
          <h2 className="text-uppercase section-title text-start count-up-text mb-3 ms-lg-auto fit-content text-white fadedown">Our <br/><span className="first-letter">S</span>tatistics</h2>
          <div className="d-flex justify-content-end flex-wrap">
            <div className="count-up fadedown">
              <span className="counter-count font-roman">3</span>
              <h5 className="text-uppercase">Total <br/>Projects</h5>
            </div>
            <div className="count-up fadedown delay1s">
              <span className="counter-count font-roman">2</span>
              <h5 className="text-uppercase">Completed <br/>Projects</h5>
            </div>
            <div className="count-up fadedown delay2s">
              <span className="counter-count font-roman">1</span>
              <h5 className="text-uppercase">OnGoing <br/>Projects</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  {/* <!-- builder info section --> */}
  <div className="position-relative animation-section">
    <img src={Delivering2}  className="img-fluid builders-first faderight" alt="Builders"/>
    
    <section className="builders-section common-bg">
      <div className="container">
        <div className="positioned-builder-text p-70 fade">
          <h2 className="section-title delivering-title"><span className="first-letter">“</span>Delivering more than expected every single time<span className="first-letter">”</span></h2>
          <p className="grey-text common-des">Welcome to Baagban Musk, an oasis of luxury living. Nestled amidst nature, this project offers spacious 3 BHK apartments with elegant interiors and a range of amenities. Enjoy a harmonious blend of comfort and tranquility, all within a prime location.</p>
          <p className="grey-text common-des">Dive into a world of literary bliss in our library. Cozy corners, captivating stories, and quiet escapes await—your haven of stillness and endless possibilities between the pages.</p>
          <a href="tel:+916356603030" className="btn btn-lg btn-bordered-dark me-3">Call Us Now</a>
        </div>
      </div>
    </section>
    <img src={Delivering1}  className="img-fluid builders-last fadeleft" alt="Builders"/>

  </div>


  {/* <!-- video section --> */}
  <section className="video-section no-padding animation-section">
    <div className="d-flex">
      <div className="position-relative col-12 video">
      <Fancybox options={{Carousel: {infinite: false,},}}>
        <button type="button" className="btn btn-play fancy" data-src="https://youtu.be/c6T4_2ALmDc?si=CAdP_q2kaUPYPf1n" data-fancybox="wedding-function-photos"><span className="play-icon fi fi-rs-play" aria-label="Play"></span></button>
        </Fancybox>

        <img src={aboutVideoBG}  className="img-responsive w-100 bg-thumb-img" alt="Video"/>
      </div>
    </div>
  </section>
    </Layout>
  );
}