
import { Link  } from "react-router-dom";
import * as Constants from "../config/constants.js";
import React, { useEffect} from "react";
import Layout from "./layout.js";
import "@flaticon/flaticon-uicons/css/all/all.css";
import { useTranslation } from "react-i18next";
import Delivering1 from "../images/delivering-img1.jpg";
import Delivering2 from "../images/delivering-img2.jpg";

export default function About() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const { t } = useTranslation(["translation", "errorMessage"]);

  return (
    <Layout pageName={t("about-us")}>
      <header className="breadcrumb about-us-bread">
        <div className="container">
          <div className="breadcrumb-sec">
            <h1 className="page-title">{t("about-us")}</h1>
            <div className="breadcrumb-menu ml-auto">
              <ul>
                <li className="nav-item"><Link to={Constants.HOME_PAGE_URL} className="nav-link">{t("home")}</Link></li>
                <li className="nav-item">/</li>
                <li className="nav-item">{t("about-us")}</li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      <main className="page-height">
      {/* <!-- about section -->  */}
        <section className="about-section back-img light-img-bg " >
          <div className="container">
              <div className="row">
                <div className="col-md-6 d-flex flex-column align-items-center justify-content-center mt-5 mt-md-0 order-2 order-md-1">
                  <div className="masking-img">
                    <h3 className="masking-text">20+</h3>
                  </div>
                  <p className="my-0 font-25 about-des">Years of Experience</p>
                </div>
                <div className="col-md-6 order-1 order-md-2">
                  <h5 className="text-uppercase section-title">About <span className="first-letter">B</span>aagban Group</h5>
                  <p className="grey-text common-des">Baagban Group creates an ideal community living experience in Ahmedabad. It also a very strategic location for your home.  Since beginning, Baagban Group stands for expertise and quality. We create best in class luxury homes 2 BHK & 3 BHK to bring our customer's dreams into reality. Sanaulla R. Pathan is a man of great vision. A thinker. A doer. A go-getter. An Entrepreneur who is forever committed towards offering the best value and delivering high returns to his stakeholders. Over the years, Sanaulla R. Pathan has built a huge empire in real estate by implementing great ideas that drive progress for not only the end users, but also the society at large.</p>
                  <a href="tel:+916356603030" className="btn btn-lg btn-bordered-dark me-3">Call Us Now</a>
                </div>
              </div>
          </div>
        </section>
         {/* <!-- builder info section -- > */}
        <div className="position-relative">
          <img src={Delivering2}  className="img-fluid builders-first" alt="Builders"/>
          
          <section className="builders-section ">
            <div className="container">
              <div className="positioned-builder-text p-70">
                <h2 className="section-title delivering-title"><span className="first-letter">“</span>Delivering more than expected every single time<span className="first-letter">”</span></h2>
                <p className="grey-text common-des">Welcome to Baagban Musk, an oasis of luxury living. Nestled amidst nature, this project offers spacious 3 BHK apartments with elegant interiors and a range of amenities. Enjoy a harmonious blend of comfort and tranquility, all within a prime location.</p>
                <p className="grey-text common-des">Dive into a world of literary bliss in our library. Cozy corners, captivating stories, and quiet escapes await—your haven of stillness and endless possibilities between the pages.</p>
                <Link to={Constants.PROJECTS_PAGE_URL} className="btn btn-lg btn-bordered-dark me-3">View Projects</Link>
              </div>
            </div>
          </section>
          <img src={Delivering1}  className="img-fluid builders-last" alt="Builders"/>

        </div>
      </main>
    
    </Layout>
  );
}
