import React from "react";
import $ from "jquery";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import 'alertifyjs/build/css/themes/default.css';
import * as Constants from '../config/constants';
import axios from "axios";
window.jQuery = $;
const jQuery = $;
window.$ = $;
global.jQuery = $;

export const toggleNav = () => {
  document.body.classList.toggle("nav-slide-open");
};
document.addEventListener("click", function(e) {
	if (window.innerWidth < 992 && !e.target.closest("#slide-toggle")) {
		document.body.classList.remove("nav-slide-open");
	}
});

export function validationError(error) {
    return(error && ((<span className="text-danger">{error?.message}</span>)));
}

(function($) {
	$.extend({
		ovrly: function(wrapper) {
			var container = wrapper ? wrapper : "body";
			var methods = {
				init: function() {
					$(container)
						.addClass('rel-pos')
						.children(".overlay-block")
						.remove();

					var style = '';
					if (wrapper === '' || wrapper === 'body' || wrapper === undefined) {
						style = 'style="position:fixed; height: 100vh; width: 100vw;z-index: 1099;"';
					}

					$(`<div class="overlay-block" ${ style }>
						<div>
							<div class="spinner-border text-light" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</div>
					</div>`).appendTo(container);

					if ($('#overlayBlockStyle').length < 1) {
						$(`<style id="overlayBlockStyle">
								.rel-pos { position:relative !important; overflow-y: hidden; }
								.overlay-block { position: absolute; height: 100%; width: 100%; top: 0; left: 0; background-color: rgba(30, 30, 30, 0.75); z-index: 1010; color: #fff; display: flex; align-items: center; justify-content: center; }
							</style>`
						).appendTo("body");
					}
				},
				kill: function() {
					$(container).find(".overlay-block").fadeOut(150, function() {
						$(this).remove();
						setTimeout(() => {
							$(container).removeClass('rel-pos');
						}, 150);
					});
				}
			};

			return methods;
		}
	});

	$.fn.elasticMenu = function() {
		if ($(window).innerWidth() < 992) {
			return;
		} else {
			var $elm = $(this);
			$elm.each(function() {
				var $nav = $(this);
				var activeItem = $($nav).find(".active");
				var navItems = $($nav).attr("data-bs-targets");
				var shadow = $("<div>", { class: "nav-shadow" }).css({
					width: 0,
					transform: "translate3d(-50%,-100%, 0)",
					opacity: 0
				});

				// activeItem.addClass("is-active");
				shadow.insertAfter($nav);

				// here i_* = initial;
				var i_top = 0;
				var i_left = 0;
				var i_height = 0;
				var i_width = 0;
				var i_opacity = 0;

				function UpdateActiveCoords() {
					if (activeItem.length === 1) {
						i_top = activeItem.offset().top;
						i_left = activeItem.offset().left;
						i_height = activeItem.outerHeight();
						i_width = activeItem.outerWidth();
						i_opacity = 1;
					} else {
						i_top = $nav.offset().top;
						i_left = $nav.offset().left;
						i_height = $nav.outerHeight();
						i_width = 0; //$nav.outerWidth();
						i_opacity = 0;
					}
				}

				function moveShadow(t, l, h, w, o) {
					shadow.css({
						"background-color": "#f90",
						"transition": "0.35s all",
						"opacity": o,
						"position": "fixed",
						"z-index": -1,
						"height": h,
						"width": w,
						"left": l + w / 2
					});
				}

				UpdateActiveCoords();
				moveShadow(i_top, i_left, i_height, i_width, i_opacity);

				// here c_* = current
				var c_height;
				var c_width;
				var c_top;
				var c_left;
				var c_opacity;

				$(navItems).each(function() {
					$(this).hover(
						function() {
							c_height = $(this).outerHeight();
							c_width = $(this).outerWidth();
							c_top = $(this).offset().top;
							c_left = $(this).offset().left;
							c_opacity = 1;
							// console.log(top, left, height, width);
							moveShadow( c_top , c_left , c_height , c_width , c_opacity );
						},
						function() {
							moveShadow( i_top , i_left , i_height , i_width , i_opacity );
						}
					);
					$(window).on("resize scroll", function() {
						setTimeout(function() {
							if (activeItem.length === 1) {
								UpdateActiveCoords();
								moveShadow( i_top , i_left , i_height , i_width , i_opacity );
							}
						}, 300);
						// console.log(left, top, height, width);
					});
				});
			});
		}
	};
})(jQuery);

export function showLoader() {
	$.ovrly().init();
}

export function hideLoader() {
	$.ovrly().kill();
}

alertify.defaults.transition = "slide";
alertify.defaults.theme.ok = "btn btn-primary";
alertify.defaults.theme.cancel = "btn btn-danger";
alertify.defaults.theme.input = "form-control";
export function alertifyMessage(type, message) {
	switch (type) {
		case "error":
			alertify.notify(message, "error", 5);
			break;
		case "success":
			alertify.notify(message, "success", 5);
			break;
		case "warning":
			alertify.notify(message, "warning", 5);
			break;
		case "info":
			alertify.notify(message);
			break;
		default:
			alertify.notify(message);
	}
}

export function manageNetworkError(response , t = null ) {
	
	var error_code = response.data.code ;
	if( error_code === "ERR_NETWORK"){
		alertifyMessage("error" , "Network Error. Please Contact System Administrator" );
	}
}

export const apiCall = async (method = 'get' ,  url ,  data = null ) => {
	
    let user = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));

    let client = axios.create({
		  baseURL: Constants.API_BASE_URL,
		  headers: {
	            'Content-Type': 'multipart/form-data;charset=UTF-8',
	            "Access-Control-Allow-Origin": "*",
	            'x-access-token': ((user && user.token) ? user.token : undefined),
	     }
	});
    var result = {};
	var response = {};
	showLoader();
	if( method === "get" ){
    	
    	try {
    		result =  client.get(url).then((responseData) => {
    			hideLoader();
    			response.status = true;
    			response.data = responseData.data;
	            return response;
	        }).catch((error) => {
	        	hideLoader();
	        	response.status = false;
	        	response.data = error;
	            return response;
	        });
    	} catch (error) {
    		hideLoader();
    		response.status = false;
        	response.data = error;
        	return response;
    	}
    	
    	return result;
    } else if( method === "post" ){
    	try {
    		result =  client.post(url,data).then((responseData) => {
    			hideLoader();
    			response.status = true;
    			response.data = responseData.data;
	            return response;
	        }).catch((error) => {
	        	hideLoader();
	        	//console.log("catch 1");
	        	response.status = false;
	        	response.data = error;
	            return response;
	        });
    	}catch (error) {
    		hideLoader();
    		//console.log("catch 2");
    		response.status = false;
        	response.data = error;
        	return response;
    	}
    	return result;
    }
}
export function onlyNumberWithSpaceAndPlusSign(e) {
	var val = e.target.value;
	e.target.value = val.replace(/[^ +\d]/g, "");
}